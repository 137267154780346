import styled from "@emotion/styled";

import { Note } from "../../model/types";
import { useAtom } from "jotai";
import { mobileNoteIdMenuAtom } from "./MobileNoteIdMenuAtom";
import { Background } from "./NoteMenu";
import { useMenuActions } from "./useMenuActions";
import { formatDate } from "../../utils/date";
import { FolderList } from "./FolderList";
import { folderList } from "../../model/services";

const MobileMenuContainer = styled.div`
  position: fixed;
  right: 18px;
  top: 36px;
  z-index: 11;
`;

const MainMenu = styled.div`
  background-color: var(--color-bg-elevated);
  width: 240px;
  min-height: 200px;
  color: var(--color-text-primary);
  padding: 8px 0;
  border-radius: var(--radius-large);
  box-shadow: var(--shadow-heavy);
  border: var(--color-border-secondary);
`;

// Used on mobile and narrow displays

export const ModalMenu = ({ note }: { note: Note }) => {
  const [, setNoteId] = useAtom(mobileNoteIdMenuAtom);
  const closeMenu = () => {
    setNoteId(null);
    document
      .querySelectorAll(".note-menu-button-mobile.active")
      .forEach((button) => button.classList.remove("active"));
  };
  const a = useMenuActions(note, closeMenu);

  const folder = note.folderId !== null ? folderList.get(note.folderId) : null;

  return (
    <>
      <Background
        style={{ backgroundColor: `black`, opacity: 0.2 }}
        onClick={() => {
          a.toggleMenuExpanded(null);
          closeMenu();
        }}
      />
      <MobileMenuContainer>
        {a.menuExpanded() === null && (
          <MainMenu>
            {note.readAll ? (
              <>
                <button className="menu-item" onClick={a.share}>
                  Share
                </button>
                <button className="menu-item" onClick={a.togglePublish}>
                  Stop publishing
                </button>
              </>
            ) : (
              <button className="menu-item" onClick={a.share}>
                Publish and share
              </button>
            )}
            <button className="menu-item" onClick={a.goToDay}>
              Show day
            </button>
            <button className="menu-item" onClick={a.jump}>
              Show in timeline
            </button>
            <button className="menu-item" onClick={a.copy}>
              Copy text
            </button>
            <button className="menu-item" onClick={a.copyUrl}>
              Copy url
            </button>
            <button
              className="menu-item"
              onClick={() => {
                a.toggleMenuExpanded("folder")();
              }}
            >
              {folder
                ? `Change folder (current: ${folder.name})`
                : `Add to folder...`}
            </button>
            <button className="menu-item" onClick={a.togglePin}>
              {note.positionInPinned ? `Unpin note` : `Pin Note`}
            </button>
            <button className="menu-item" onClick={a.openAsPage}>
              Open As page
            </button>
            <hr />
            <div className="menu-info">
              Created {formatDate(note.createdAt).toLowerCase()}
            </div>
            <div className="menu-info">
              Updated {formatDate(note.updatedAt).toLowerCase()}
            </div>
          </MainMenu>
        )}
        {a.menuExpanded() === "folder" && (
          <FolderList
            note={note}
            forceUpdate={a.forceUpdate}
            closeMenu={() => a.toggleMenuExpanded(null)()}
          />
        )}
      </MobileMenuContainer>
    </>
  );
};
