import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { SortBy } from "../search/SearchQuery";
import { colors } from "../utils/style";
import { ChevronDown } from "react-feather";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 3px;
`;

const Menu = styled.div`
  position: relative;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ButtonLabel = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: ${colors.text.secondary};
  cursor: pointer;
  user-select: none;
`;

const Value = styled.div`
  margin-left: 8px;
  margin-right: 5px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  user-select: none;
`;

const Select = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid var(--color-border-secondary);
  background: var(--color-bg-elevated);
  box-shadow: var(--shadow-heavy);
  padding: 4px 0px 8px;
  border-radius: var(--radius-large);
  z-index: 4;
  min-width: 200px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  height: 36px;
  text-align: left;
  font-size: 14px;
  border: none;
  background-color: var(--color-bg-elevated);
  color: var(--color-text-primary);
  cursor: pointer;
  :hover {
    background-color: var(--color-bg-tertiary);
  }
`;

type Props = {
  label: string;
  currentValue: SortBy;
  setValue: (value: SortBy) => void;
};

export function SearchResultsSortMenu({
  label,
  currentValue,
  setValue,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const select = useRef<HTMLDivElement>(null);
  const button = useRef<HTMLDivElement>(null);

  const options: { value: SortBy; label: string }[] = [
    { value: SortBy.relevance, label: "Most relevant" },
    { value: SortBy.lastUpdated, label: "Last updated" },
    { value: SortBy.position, label: "Position" },
  ];
  const currentOptionLabel = options.find(
    (opt) => opt.value === currentValue,
  )?.label;
  if (!currentOptionLabel) {
    throw new Error(`Invalid sortBy value: ${currentValue}`);
  }

  function closeHangingMenu(e: any) {
    if (select.current && select.current.contains(e.target)) {
      return;
    }
    if (button.current && button.current.contains(e.target)) {
      return;
    }
    closeMenu();
  }

  function openMenu() {
    setIsOpen(true);
    document.addEventListener("mousedown", closeHangingMenu);
  }

  function closeMenu() {
    setIsOpen(false);
    document.removeEventListener("mousedown", closeHangingMenu);
  }

  function toggleMenu() {
    if (isOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  function clickOptionHandler(value: SortBy) {
    setValue(value);
    closeMenu();
  }

  return (
    <Wrapper id="search-sort-menu">
      <Menu>
        <Button ref={button} onClick={toggleMenu}>
          {label && <ButtonLabel>{label}</ButtonLabel>}
          <Value>{currentOptionLabel}</Value>
          <ChevronDown size={16} />
        </Button>
        {isOpen && (
          <Select ref={select}>
            {options.map(({ value, label }) => (
              <Option
                data-value={value}
                key={value}
                onClick={() => clickOptionHandler(value)}
              >
                {label}
              </Option>
            ))}
          </Select>
        )}
      </Menu>
    </Wrapper>
  );
}
