import styled from "@emotion/styled";
import React from "react";
import { colors, mediaQueries } from "../utils/style";
import { Search } from "react-feather";

const Container = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: ${colors.text.tertiary};
  ${mediaQueries.mobile} {
    display: none;
  }
`;

export const MagnifyingGlass = React.memo(() => (
  <Container>
    <Search size={20} />
  </Container>
));
