import { isSidebarOpenAtom } from "../sidebar/atoms/isSidebarOpenAtom";
import { useCallback } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useSearchState } from "../search/searchStateAtom";
import { mediumWidth } from "../utils/style";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Selection } from "prosemirror-state";
import { noteList } from "../model/services";
import { trackEvent } from "../analytics/analyticsHandlers";
import { noteToProsemirrorNode } from "../editor/bridge";
import { editorViewAtom } from "./atoms/editorViewAtom";
import { scrollEditorToTop } from "./EditorWithAutocomplete";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";

export const wasAtLeastOneNoteAddedAtom = atom<boolean>(false);

export const useCreateNote = () => {
  const editorView = useAtomValue(editorViewAtom);
  const [searchQuery] = useSearchState();
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const { width } = useWindowDimensions();
  const updateSidebar = useNotifySidebarUpdate();

  const setWasAtLeastOneNoteAdded = useSetAtom(wasAtLeastOneNoteAddedAtom);

  const callback = useCallback(
    (text = "") => {
      if (!editorView) return;
      if (width < mediumWidth) setShowSidebar(false);
      const [note] = noteList.insert({
        createdAt: searchQuery.type === "date" ? searchQuery.q : undefined,
        folderId: searchQuery.type === "folder" ? searchQuery.q : undefined,
        strings: [text],
      });
      trackEvent([
        "NOTE",
        note.folderId ? "CREATED_FROM_FOLDER" : "CREATED",
        note.id!,
      ]);
      setWasAtLeastOneNoteAdded(true);

      const tr = editorView.state.tr.insert(0, noteToProsemirrorNode(note));

      tr.setSelection(Selection.atStart(tr.doc));
      editorView.dispatch(tr);

      // Both of these must be performed after dispatching the transaction that
      // sets the selection, otherwise they're unreliable
      scrollEditorToTop();
      editorView.focus();

      updateSidebar();
    },
    [
      editorView,
      setShowSidebar,
      searchQuery,
      width,
      setWasAtLeastOneNoteAdded,
      updateSidebar,
    ],
  );

  return callback;
};
