import styled from "@emotion/styled";
import { getShortcutString, shortcuts } from "../shortcuts/shortcuts";
import { Home } from "react-feather";
import { useHome } from "./navigateHistory";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: 44px;
  min-height: 44px;
  background: transparent;
  border: 0;
  color: var(--color-text-secondary);
  :disabled {
    color: var(--color-text-tertiary);
    cursor: default;
  }
  padding-top: 0;
`;

export const HomeButton = ({
  callback,
  disabled = false,
  size = 20,
}: {
  callback?: () => void;
  disabled?: boolean;
  size?: number;
}) => {
  const home = useHome();
  return (
    <Button
      id="home-button"
      role="tooltip"
      data-microtip-position="right"
      aria-label={`All Notes ${getShortcutString(shortcuts.backToAllNotes)}`}
      onMouseDown={() => {
        home();
        callback?.();
      }}
      disabled={disabled}
    >
      <Home size={size} />
    </Button>
  );
};
