import React, { useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  colors,
  radii,
  mediaQueries,
  mediumWidth,
  shadows,
  transitions,
} from "../utils/style";
import { useAtomValue, useSetAtom } from "jotai";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { shortcuts } from "../shortcuts/shortcuts";
import { useSearchState } from "./searchStateAtom";
import { Selection } from "prosemirror-state";
import { MagnifyingGlass } from "./MagnifyingGlass";
import { isIOs } from "../utils/environment";
import debounce from "lodash.debounce";
import { isSidebarOpenAtom } from "../sidebar/atoms/isSidebarOpenAtom";
import { X } from "react-feather";
import { trackEvent } from "../analytics/analyticsHandlers";
import { editorViewAtom } from "../editorPage/atoms/editorViewAtom";
import { isNoteFilter, SearchQuery } from "./SearchQuery";

export const searchInputId = "ideasearch";

const SInput = styled.input`
  padding: 9px 40px 9px 44px;
  height: 36px;
  border-radius: ${radii.medium};
  width: 100%;
  outline: none;
  background: transparent;
  color: ${colors.text.primary};
  border: 0;
  background: ${colors.bg.secondary};
  transition: ${transitions.light};

  :placeholder {
    color: ${colors.text.secondary};
    transition: ${transitions.light};
  }

  ${mediaQueries.mobile} {
    padding-left: 16px;
    height: 40px;
  }

  :hover {
    background: ${colors.bg.tertiary};
  }

  :focus {
    background: ${colors.bg.secondary};
    box-shadow: 0 0 0 3px ${colors.bg.accent.secondary}, ${shadows.medium};
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.125rem;
  padding: 0 0.5rem;
  font-family: "Helvetica Neue"; // override for iOS to ensure consistent Unicode font sizing
  font-size: 20;
  border: none;
  background: none;
  color: ${colors.text.secondary};
  border-radius: ${radii.medium};

  :hover {
    color: ${colors.text.primary};
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-right: 8;
`;

const RightGroupContainer = styled.div`
  position: "relative";
  width: 0;
`;

const RightButtonGroup = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const qToText = (query: SearchQuery) => {
  if (query.type === "is") return `is:${query.q}`;
  if (query.type === "hashtag" || query.type === "text") return query.q;
  return "";
};

const Search = () => {
  const [searchQuery, setSearchQuery] = useSearchState();
  const [inputText, setInputText] = useState<string | null>(null);
  const [searchState] = useSearchState();

  const input = inputText ?? qToText(searchState);
  const view = useAtomValue(editorViewAtom);

  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => inputRef.current?.select();
  useHotkeys(shortcuts.search, focusInput);
  useHotkeys(shortcuts.searchAlt, focusInput);

  // if user stops to type for 600ms/800ms, we execute the query
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnInputChange = useMemo(
    () =>
      debounce(
        (value) => {
          const newQuery = fromSearchBar(value, searchQuery);
          setSearchQuery(newQuery);
          trackEvent(["SEARCH", "SEARCH"]);
        },
        isIOs ? 800 : 600,
        {
          // Don't fire on the first keystroke -- wait until user stops typing
          leading: false,
        },
      ),
    [searchQuery, setSearchQuery],
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (window.innerWidth <= mediumWidth) {
      setShowSidebar(false);
    }
    // set the component state immediately on every change
    setInputText(value);
    debouncedOnInputChange(value);
  };

  const onInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    // if user hits tab, we focus the editor
    if (evt.key !== "Tab" || evt.shiftKey) return;
    evt.preventDefault();
    if (!view) return;

    // move selection to start of editor
    const tr = view.state.tr;
    const selection = Selection.atStart(tr.doc);
    tr.setSelection(selection);
    tr.scrollIntoView();
    view.dispatch(tr);
    // need to focus editor for selection to be useful
    view.focus();
  };

  return (
    <Container role="search">
      <MagnifyingGlass />
      <div style={{ width: "100%", display: "flex" }}>
        <SInput
          id={searchInputId}
          type="text"
          autoComplete="off"
          value={input}
          ref={inputRef}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onBlur={() => setInputText(null)}
          placeholder={"Search Notes"}
        />
        <RightGroupContainer>
          <RightButtonGroup>
            {input !== "" && (
              <CloseButton
                onClick={() => {
                  // don't need to debounce clearing search bar
                  setSearchQuery({ type: "*" });
                  inputRef.current?.focus();
                }}
              >
                <X size={20} />
              </CloseButton>
            )}
          </RightButtonGroup>
        </RightGroupContainer>
      </div>
    </Container>
  );
};

// Header is rerendered every time the view changes
// Search doesn't need to know anything about the editorView
export default React.memo(Search);

export const fromSearchBar = (
  text: string,
  previousSearchQuery: SearchQuery,
): SearchQuery => {
  const { isCondensed, isIncludingSubFolders, sortBy } = previousSearchQuery;
  const persistent = { isCondensed, isIncludingSubFolders, sortBy };
  if (/\s/g.test(text)) {
    // two words or more is text
    return {
      type: "text",
      q: text,
      ...persistent,
    };
  }
  if (text.startsWith("is:")) {
    const isTypeValue = text.substring(3);
    if (isNoteFilter(isTypeValue)) {
      return {
        type: "is",
        q: isTypeValue,
        ...persistent,
      };
    }
  }
  return {
    type: "text",
    q: text,
    ...persistent,
  };
};
