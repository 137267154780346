import React from "react";
import { Edit } from "react-feather";
import styled from "@emotion/styled";
import { radii, colors } from "../utils/style";
import { getShortcutString, shortcuts } from "../shortcuts/shortcuts";
import { useCreateNote } from "./useCreateNote";
import { footerHeight } from "./Footer";

export const newNoteButtonId = "newNoteButton";

const tooltip = `Create a new note ${getShortcutString(
  shortcuts.createNewNote,
)}.\nSplit notes ${getShortcutString(shortcuts.splitNote)}`;

export const NewNoteButtonWithInteraction = () => {
  const createNote = useCreateNote();
  return (
    <div
      id={newNoteButtonId}
      aria-label={tooltip}
      data-microtip-position="left"
      role="tooltip"
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        padding: "4px 0px 4px 4px",
        borderRadius: "100%",
      }}
      onClick={() => createNote()}
    >
      <NewNoteButton />
    </div>
  );
};

export const NewNoteButtonMobileWithInteraction = () => {
  const createNote = useCreateNote();
  return (
    <div
      aria-label={tooltip}
      data-microtip-position="left"
      role="tooltip"
      id={newNoteButtonId}
      style={{
        position: "fixed",
        zIndex: 5,
        right: 32,
        bottom: footerHeight + 5,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: colors.bg.accent.primary,
        color: colors.bg.primary,
        height: 64,
        width: 64,
        borderRadius: "100%",
        userSelect: "none",
      }}
      onPointerDown={(e) => {
        createNote();
        e.preventDefault();
      }}
    >
      <Edit
        size={26}
        style={{
          marginLeft: 1,
          marginBottom: 1,
          strokeWidth: "1.5px",
        }}
      />
    </div>
  );
};

const ButtonContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.medium};
  background: ${colors.bg.accent.primary};
  color: ${colors.bg.primary};

  :hover {
    background: ${colors.text.accent};
  }
`;

const NewNoteButton = () => (
  <ButtonContainer>
    <Edit
      size={20}
      style={{
        marginLeft: 1,
        marginBottom: 1,
      }}
    />
  </ButtonContainer>
);
