import React from "react";
import { SearchQuery } from "../search/SearchQuery";
import { colors } from "../utils/style";

export const NoResults = ({
  createNewNote,
  searchQuery,
}: {
  createNewNote: (text: string) => void;
  searchQuery: SearchQuery;
}) => {
  let noResultsMessage: string;
  switch (searchQuery.type) {
    case "text":
      noResultsMessage = `No notes containing "${searchQuery.q}" found.`;
      break;
    case "date":
      noResultsMessage = `No notes for the specified date yet.`;
      break;
    default:
      noResultsMessage = "No notes yet.";
  }
  return (
    <div
      style={{
        width: "720px",
        marginTop: 36,
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        boxSizing: "content-box",
        padding: "0 24px",
        color: colors.text.secondary,
      }}
    >
      {noResultsMessage} Why not{" "}
      <button
        style={{
          background: "transparent",
          border: 0,
          display: "inline",
          textDecoration: "underline",
          padding: 0,
          margin: 0,
          cursor: "pointer",
          color: colors.text.accent,
        }}
        onClick={() => {
          createNewNote(searchQuery.type === "text" ? searchQuery.q + " " : "");
        }}
      >
        create one
      </button>
      ?
    </div>
  );
};
