let div: any = null;
let timeout = 0;

const cleanup = () => {
  if (div) {
    document.body.removeChild(div);
    div = null;
  }
};

export const toast = (
  message: string,
  button?: { text: string; onClick(): void },
): void => {
  if (timeout) {
    window.clearTimeout(timeout);
    cleanup();
  }
  div = document.createElement("div");
  div.classList.add("note-menu-toast");
  div.textContent = message;
  if (button) {
    const buttonEl = document.createElement("button");
    buttonEl.textContent = button.text;
    buttonEl.addEventListener("click", button.onClick);
    div.appendChild(buttonEl);
  }
  document.body.appendChild(div);
  timeout = window.setTimeout(cleanup, 4000);
};
