// stolen from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string) {
  // clipboard is not set in unit tests
  if (!(navigator as any).clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  (navigator as any).clipboard.writeText(text).then(
    function () {},
    function (err: any) {
      console.error("could not copy text: ", err);
    },
  );
}
