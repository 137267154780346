import debounce from "lodash.debounce";
import { useEffect, useState } from "react";

interface ElementScreenPosition {
  scrollTop: number;
  top: number;
}

function findElementScreenPosition(
  element: HTMLElement,
): ElementScreenPosition {
  return {
    scrollTop: element.scrollTop,
    top: element.getBoundingClientRect().top,
  };
}

export function useScrollOffsets(element: HTMLElement | null) {
  const [placement, setPlacement] = useState<ElementScreenPosition>({
    scrollTop: 0,
    top: 0,
  });

  useEffect(() => {
    if (!element) return;

    // We wait until the resize observer callback is called
    // before measuring the bounding client rect of the element
    // This fixes the following bug in Safari: https://linear.app/ideaflow/issue/ENT-1429
    // It appears that Safari handles layout of elements differently from Chrome
    // and elements can return 0 bounding client rect even after being added to the DOM
    const resizeObserver = new ResizeObserver(() => {
      setPlacement(findElementScreenPosition(element));
      resizeObserver.disconnect();
    });
    resizeObserver.observe(element);

    const handleScroll = debounce(() => {
      setPlacement(findElementScreenPosition(element));
    }, 100);
    element?.addEventListener("scroll", handleScroll);
    return () => {
      resizeObserver.disconnect();
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [element]);

  return placement;
}
