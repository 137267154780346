import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useCreateNote } from "./useCreateNote";
import { EditorView } from "prosemirror-view";
import { schema } from "../editor/schema";
import { Selection } from "prosemirror-state";
import { listCreateOrWrap } from "../editor/features/list/createNestedListPlugin";
import { colors, shadows } from "../utils/style";
import {
  decrementDepthCommand,
  incrementDepthCommand,
} from "../editor/features/list/handleListActionsPlugin";
import { DedentIcon } from "./img/DedentIcon";
import { IndentIcon } from "./img/IndentIcon";
import { toggleCheckbox } from "../editor/features/checkbox/toggleCheckboxCommand";
import { useAtomValue } from "jotai";
import { editorViewAtom } from "./atoms/editorViewAtom";
import { chainCommands } from "prosemirror-commands";
import { dedent, indent } from "../editor/features/text/tabCommands";
import { newNoteButtonId } from "./NewNoteButton";
import {
  RotateCcw,
  RotateCw,
  CheckSquare,
  Hash,
  List,
  Plus,
  Edit,
} from "react-feather";
import { undo, redo } from "prosemirror-history";
import { trackEvent } from "../analytics/analyticsHandlers";

const iconSize = 25;

const MobileBar = styled.div`
  background-color: ${colors.bg.secondary};
  border: 1px solid ${colors.border.secondarySolid};
  position: fixed;
  width: 100%;
  z-index: 100;
  display: none;
  box-shadow: ${shadows.heavy};
  justify-content: space-between;
  align-items: center;

  .mobile-bar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    user-select: none;
  }
`;

const ScrollingSection = styled.div`
  display: flex;
  overflow-x: scroll;
  .mobile-bar-button {
    color: ${colors.text.secondary};
  }
`;

const FixedSection = styled.div`
  display: flex;
  align-items: center;
  .mobile-bar-button {
    color: ${colors.text.tertiary};
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: ${iconSize * 1.2}px;
  background-color: ${colors.border.secondarySolid};
`;

export const MobileKeyboardBar = () => {
  const view = useAtomValue(editorViewAtom);
  return view ? <Bar view={view} /> : null;
};

const Bar = ({ view }: { view: EditorView }) => {
  // keyboardBar is a ref to the DOM element, but since we're using it in a useEffect callback
  // we can't use useRef() directly. See https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  const [keyboardBar, setKeyboardBar] = useState<HTMLElement | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const createNote = useCreateNote();

  useEffect(() => {
    function repositionBar() {
      if (!keyboardBar || !window.visualViewport) return;

      const newNoteButton = document.getElementById(
        newNoteButtonId,
      ) as HTMLElement;

      const isKeyboardVisible =
        window.visualViewport.height / window.innerHeight < 0.7;

      if (isKeyboardVisible && view.hasFocus()) {
        keyboardBar.style.display = "flex";
        keyboardBar.style.top = `${
          window.visualViewport.height -
          keyboardBar.clientHeight +
          window.visualViewport.pageTop
        }px`;

        // If the user tapped on a note that is covered by the keyboard bar,
        //  then manually scroll the window down a bit
        const dom = view!.domAtPos(view!.state.selection.from);
        const el =
          dom?.node.nodeType === Node.TEXT_NODE
            ? dom.node.parentElement
            : (dom?.node as Element);
        if (el) {
          const cursorClientRect = el.getBoundingClientRect();
          const keyboardBarRect = keyboardBar.getBoundingClientRect();
          if (cursorClientRect.top > keyboardBarRect.top) {
            window.scrollBy(0, cursorClientRect.top - keyboardBarRect.top);
          }
        }

        if (newNoteButton) {
          newNoteButton.style.display = "none";
        }
      } else {
        keyboardBar.style.display = "none";
        if (newNoteButton) {
          newNoteButton.style.display = "flex";
        }
      }
    }

    function hideBar() {
      if (!keyboardBar) return;
      keyboardBar.style.display = "none";
    }

    window.visualViewport?.addEventListener("resize", repositionBar);
    window.visualViewport?.addEventListener("scroll", repositionBar);
    window.addEventListener("click", repositionBar);
    window.addEventListener("orientationchange", hideBar);

    return () => {
      window.visualViewport?.removeEventListener("resize", repositionBar);
      window.visualViewport?.removeEventListener("scroll", repositionBar);
      window.removeEventListener("click", repositionBar);
      window.removeEventListener("orientationchange", hideBar);
    };
  }, [keyboardBar, view]);

  function appendSpaceshipOrHashtag(type: "+" | "#") {
    let text = ` ${type}`;
    const currentPos = view.state.selection.$from;
    if (currentPos.parent.type === schema.nodes.paragraph) {
      const currentChar =
        currentPos.parent.textContent[currentPos.parentOffset - 1];
      if (currentChar === " " || currentPos.parentOffset === 0) {
        text = type;
      }
    }

    const tr = view.state.tr.replaceWith(
      view.state.selection.from,
      view.state.selection.to,
      schema.text(text),
    );

    const newPos = view.state.selection.from + text.length;
    tr.setSelection(Selection.near(tr.doc.resolve(newPos)));
    tr.scrollIntoView();

    view.dispatch(tr);

    // Track event
    if (type === "+") {
      trackEvent(["RELATIONS", "CREATED_FROM_MOBILE_BAR"]);
    } else if (type === "#") {
      trackEvent(["HASHTAG", "CREATED_FROM_MOBILE_BAR"]);
    }
  }

  const MobileBarButton = ({
    onTouchEnd,
    children,
  }: {
    onTouchEnd: () => void;
    children: React.ReactNode;
  }) => {
    return (
      <div
        className="mobile-bar-button"
        onTouchEnd={(e) => {
          if (isScrolling) return;
          e.preventDefault();
          onTouchEnd();
          view.focus();
        }}
      >
        {children}
      </div>
    );
  };

  return (
    <MobileBar
      ref={setKeyboardBar}
      role="keyboard-bar"
      onTouchStart={() => setIsScrolling(false)}
      onTouchMove={() => setIsScrolling(true)}
    >
      <ScrollingSection>
        <MobileBarButton onTouchEnd={() => appendSpaceshipOrHashtag("+")}>
          <Plus size={iconSize} />
        </MobileBarButton>
        <MobileBarButton onTouchEnd={() => appendSpaceshipOrHashtag("#")}>
          <Hash size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          onTouchEnd={() => {
            const tr = listCreateOrWrap(view.state, false);
            if (tr) {
              tr.setSelection(
                Selection.near(tr.doc.resolve(view.state.selection.to + 2)),
              );
              tr.scrollIntoView();
              view.dispatch(tr);
              trackEvent(["LIST", "CREATED_FROM_MOBILE_BAR"]);
            }
          }}
        >
          <List size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          onTouchEnd={() => {
            chainCommands(decrementDepthCommand, dedent)(
              view.state,
              view.dispatch,
            );
            trackEvent(["LIST", "CHANGE_DEPTH_FROM_MOBILE_BAR"]);
          }}
        >
          <DedentIcon size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          onTouchEnd={() => {
            chainCommands(incrementDepthCommand, indent)(
              view.state,
              view.dispatch,
            );
            trackEvent(["LIST", "CHANGE_DEPTH_FROM_MOBILE_BAR"]);
          }}
        >
          <IndentIcon size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          onTouchEnd={() => {
            const selection = view.state.selection;

            // If we're in a paragraph, then either insert a checkbox at the beginning of the current line, or toggle the existing checkbox
            // In other contexts, just insert the checkbox at the current cursor
            if (selection.$from.parent.type === schema.nodes.paragraph) {
              const didToggle = toggleCheckbox(view.state, view.dispatch);

              // If we couldn't toggle an existing checkbox, then insert a checkbox at the beginning of the line
              if (!didToggle) {
                const checkboxNode = schema.nodes.checkbox.create({
                  isChecked: false,
                });
                view.dispatch(
                  view.state.tr.insert(selection.$from.start(), checkboxNode),
                );
              }
            } else {
              const checkboxNode = schema.nodes.checkbox.create({
                isChecked: false,
              });
              view.dispatch(
                view.state.tr.insert(view.state.selection.anchor, checkboxNode),
              );
            }
            trackEvent(["CHECKBOX", "CREATED_FROM_MOBILE_BAR"]);
          }}
        >
          <CheckSquare />
        </MobileBarButton>
        <MobileBarButton
          onTouchEnd={() => {
            undo(view.state, view.dispatch);
            trackEvent(["UNDO", "FROM_MOBILE_BAR"]);
          }}
        >
          <RotateCcw size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          onTouchEnd={() => {
            redo(view.state, view.dispatch);
            trackEvent(["REDO", "FROM_MOBILE_BAR"]);
          }}
        >
          <RotateCw size={iconSize} />
        </MobileBarButton>
      </ScrollingSection>
      <FixedSection>
        <VerticalLine />
        <MobileBarButton onTouchEnd={() => createNote()}>
          <Edit size={iconSize} />
        </MobileBarButton>
      </FixedSection>
    </MobileBar>
  );
};
