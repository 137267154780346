import { Note } from "../../model/types";
import { formatDate } from "../../utils/date";

export const More = ({
  note,
  actions: a,
}: {
  note: Note;
  actions: { [key: string]: (_: any) => void };
}) => {
  return (
    <div className="note-menu-expanded">
      {note.readAll ? (
        <>
          <button className="menu-item" onClick={a.share}>
            Share
          </button>
          <button className="menu-item" onClick={a.togglePublish}>
            Stop publishing
          </button>
        </>
      ) : (
        <button className="menu-item" onClick={a.share}>
          Publish and share
        </button>
      )}
      <button className="menu-item" onClick={a.goToDay}>
        Show day
      </button>
      <button className="menu-item" onClick={a.jump}>
        Show in timeline
      </button>
      <button className="menu-item" onClick={a.copy}>
        Copy text
      </button>
      <button className="menu-item" onClick={a.copyUrl}>
        Copy url
      </button>
      <hr />
      <div className="menu-info">
        Created {formatDate(note.createdAt).toLowerCase()}
      </div>
      <div className="menu-info">
        Updated {formatDate(note.updatedAt).toLowerCase()}
      </div>
    </div>
  );
};
