import styled from "@emotion/styled";
import { getShortcutString, shortcuts } from "../shortcuts/shortcuts";
import { Search } from "react-feather";
import { searchInputId } from "../search/SearchBar";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: 44px;
  min-height: 44px;
  background: transparent;
  border: 0;
  color: var(--color-text-secondary);
  :disabled {
    color: var(--color-text-tertiary);
    cursor: default;
  }
  padding-top: 0;
`;

export const SearchButton = ({
  callback,
  size = 20,
}: {
  callback?: () => void;
  size?: number;
}) => {
  return (
    <Button
      id="search-button"
      role="tooltip"
      data-microtip-position="right"
      aria-label={`Search ${getShortcutString(shortcuts.search)}`}
      onClick={() => {
        const el = document.getElementById(searchInputId) as HTMLInputElement;
        if (!el) return;
        el.focus();
        callback?.();
      }}
    >
      <Search size={size} />
    </Button>
  );
};
