import styled from "@emotion/styled";
import { atom, useAtom } from "jotai";
import throttle from "lodash.throttle";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AutocompleteMenu } from "../editor/features/autocomplete/AutocompleteMenu";
import { useAutocompleteState } from "../editor/features/autocomplete/useAutocompleteState";
import { useSearchState } from "../search/searchStateAtom";
import { isTouchDevice } from "../utils/environment";
import { Editor } from "./Editor";
import { EditorHeader } from "./EditorHeader";
import { MobileKeyboardBar } from "./MobileKeyboardBar";
import { SearchQuery, searchQueryToPage, PageKey } from "../search/SearchQuery";
import { generatePathFromNoteId } from "../editor/utils/path";
import { NoteId } from "../model/types";

export const editorContainerId = "editor-container";

const EditorPadding = React.memo(styled.div`
  height: 80vh;
  flex-shrink: 0;
`);

const EditorScrollContainer = styled.div`
  position: relative;
  margin: 0px auto;
  min-width: 125px;
  max-width: 100%;
  padding: 0px;
  min-height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  isolation: isolate; /* Form a new stacking context to prevent dropdowns inside of the editor from showing on top of the sidebar */
`;

const EditorContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const AutocompleteWrapper = styled.div`
  position: absolute;
  inset: 0px;
  overflow: hidden;
`;

// Prevent the autocomplete elements from widening the scrollWidth of the `EditorScrollContainer`
function PreventScrollFromAbsolutelyPositionedContent({
  children,
}: {
  children: React.ReactNode;
}) {
  const divRef = React.useRef<HTMLDivElement>(null);
  return (
    <AutocompleteWrapper
      ref={divRef}
      onScroll={(e) => {
        const el = divRef.current;
        if (el) {
          el.scrollLeft = 0;
        }
      }}
    >
      {children}
    </AutocompleteWrapper>
  );
}

type scrollPositions = { [key: PageKey]: number };
const scrollPositionsAtom = atom<scrollPositions>({});

export function useEditorScrollPositions(): [
  (searchQuery: SearchQuery | PageKey) => number,
  (searchQuery: SearchQuery | PageKey, pos: number) => void,
] {
  const [scrollPositions, setScrollPositions] = useAtom(scrollPositionsAtom);

  const getScrollPosition = useCallback(
    (searchQuery: SearchQuery | PageKey) => {
      const page =
        typeof searchQuery === "string"
          ? searchQuery
          : searchQueryToPage(searchQuery);
      return scrollPositions[page] || 0;
    },
    [scrollPositions],
  );

  const setScrollPosition = useCallback(
    (searchQuery: SearchQuery | PageKey, pos: number) => {
      const page =
        typeof searchQuery === "string"
          ? searchQuery
          : searchQueryToPage(searchQuery);
      setScrollPositions((prev) => ({
        ...prev,
        [page]: pos,
      }));
    },
    [setScrollPositions],
  );

  return [getScrollPosition, setScrollPosition];
}

export const scrollEditorToTop = () => {
  document.getElementById(editorContainerId)?.scrollTo(0, 0);
};

export const scrollEditorToPosition = (position: number) => {
  document.getElementById(editorContainerId)?.scrollTo({
    top: position,
    left: 0,
    behavior: "auto",
  });
};

export const scrollEditorToNote = (noteId: NoteId) => {
  document
    .querySelector(`[data-path="${generatePathFromNoteId(noteId)}"]`)
    ?.scrollIntoView({ block: "start" });
};

export const EditorWithAutocomplete = () => {
  const [autocompleteState, setAutocompleteState] = useAutocompleteState();
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const scrollValue = useRef(0);

  const editorContainerRef = useRef<HTMLDivElement>(null);

  const autocompleteKeyDownRef = useRef<
    ((event: KeyboardEvent) => boolean) | null
  >(null);

  // if the user on mobile scrolls, we want to hide the keyboard (ENT-1051)
  const onScrollContainer = useMemo(
    () =>
      throttle(
        (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
          const scrollTop = e.currentTarget?.scrollTop;
          if (scrollTop == null) return;
          scrollValue.current = scrollTop;
        },
        300,
        { trailing: true },
      ),
    [],
  );

  useEffect(() => {
    return () => onScrollContainer.cancel();
  }, [onScrollContainer]);

  // Save scroll position on scroll
  const [, setScrollPosition] = useEditorScrollPositions();
  const [searchQuery] = useSearchState();
  const page = searchQueryToPage(searchQuery);
  function saveScrollPosition() {
    const pos = editorContainerRef.current?.scrollTop || 0;
    setScrollPosition(page, pos);
  }
  return (
    <>
      <EditorScrollContainer
        onScroll={saveScrollPosition}
        role="main"
        id={editorContainerId}
        ref={editorContainerRef}
      >
        <EditorContentContainer id="editor-content-container">
          <PreventScrollFromAbsolutelyPositionedContent>
            <AutocompleteMenu
              autocompleteState={autocompleteState}
              editorContainerRef={editorContainerRef}
              autocompleteKeyDownRef={autocompleteKeyDownRef}
            />
          </PreventScrollFromAbsolutelyPositionedContent>
          <EditorHeader searchResultsCount={searchResultsCount} />
          {isTouchDevice && <MobileKeyboardBar />}
          <Editor
            setSearchResultsCount={setSearchResultsCount}
            setAutocompleteState={setAutocompleteState}
            autocompleteKeyDownRef={autocompleteKeyDownRef}
          />
          <EditorPadding />
        </EditorContentContainer>
      </EditorScrollContainer>
    </>
  );
};
