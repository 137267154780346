import { createPortal } from "react-dom";
import { RefObject, useRef, useState } from "react";
import { Note, NoteId } from "../../model/types";

import {
  ChevronDownIcon,
  ExpandIcon,
  FolderIcon,
  FolderNoFillIcon,
  MoreIcon,
  PinIcon,
  UnpinIcon,
} from "./Icons";
import { FolderList } from "./FolderList";
import { More } from "./More";
import { useHoveredEmulation } from "./useHoveredEmulation";
import { useAtom } from "jotai";
import { mobileNoteIdMenuAtom } from "./MobileNoteIdMenuAtom";
import styled from "@emotion/styled";
import { ModalMenu } from "./ModalMenu";
import { menuUpdate, useMenuActions } from "./useMenuActions";
import { folderList, noteList } from "../../model/services";
import { isTouchDevice } from "../../utils/environment";

// check the css file for the documentation on the z-index

export const Background = styled.div`
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const NoteMenu = ({ editorDiv }: { editorDiv: RefObject<HTMLDivElement> }) => {
  if (isTouchDevice) {
    const el = document.getElementById("modal-container");
    return el ? createPortal(<OnModalMenu />, el) : <OnModalMenu />;
  } else {
    return <OnNoteHoverMenu editorDiv={editorDiv} />;
  }
};

function useNote(noteId: NoteId | null) {
  // the note may change due to the action in the note menu. To get the fresh value of the note, we force a rerender
  // when signalled by the `forceUpdate` in the `useMenuActions`.
  useAtom(menuUpdate);
  if (!noteId) return null;
  return noteList.get(noteId) ?? null;
}

const OnModalMenu = () => {
  const [noteId] = useAtom<null | NoteId>(mobileNoteIdMenuAtom);
  const note = useNote(noteId);

  return note && <ModalMenu note={note} />;
};

/**
 * A small utility hook that retains the old value from the previous render
 * when the freeze parameter is true.
 */
function useFrozenOldValue<T>(value: T, freeze: boolean) {
  const oldValueRef = useRef(value);
  if (!freeze) {
    oldValueRef.current = value;
  }

  return oldValueRef.current;
}

const OnNoteHoverMenu = ({
  editorDiv,
}: {
  editorDiv: RefObject<HTMLDivElement>;
}) => {
  const [shouldStayVisible, setShouldStayVisible] = useState(false);

  // Using `useFrozenOldValue` keeps the element and its position data alive
  // when the `shouldStayVisible` is true. This prevents the hover menu
  // from disappearing until it's no longer in use
  const elementWithBBox = useFrozenOldValue(
    useHoveredEmulation(`div[data-noteid]`, editorDiv, true),
    shouldStayVisible,
  );

  const noteId = elementWithBBox?.element.getAttribute(`data-noteid`) ?? null;
  const note = useNote(noteId);

  if (!note || !elementWithBBox) return null;

  // Calculate right position

  const parentWidth =
    editorDiv.current?.getBoundingClientRect().width ||
    document.body.clientWidth - 5;
  const rightOffset = parentWidth - elementWithBBox.relativeBBox.right;

  const editorDivOffsetTop = editorDiv.current?.offsetTop || 0;
  const noteOffsetTop = elementWithBBox.relativeBBox.top;
  const topOffset = editorDivOffsetTop + noteOffsetTop - 18;

  return (
    <div
      style={{
        position: `absolute`,
        backgroundColor: `transparent`,
        zIndex: 11,
        right: rightOffset,
        top: topOffset,
      }}
    >
      <DropMenu note={note} setShouldStayVisible={setShouldStayVisible} />
    </div>
  );
};
const DropMenu = ({
  note,
  setShouldStayVisible,
}: {
  note: Note;
  setShouldStayVisible: (t: boolean) => void;
}) => {
  const DropMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `;
  const a = useMenuActions(
    note,
    () => {
      setShouldStayVisible(false);
    },
    setShouldStayVisible,
  );
  const folder = note.folderId !== null ? folderList.get(note.folderId) : null;
  return (
    <DropMenuContainer>
      <div className="note-menu-actions">
        <button
          className={`note-menu-button folder-icon ${
            a.menuExpanded() === "folder" ? "expanded" : ""
          } ${note.folderId ? "active" : ""}`}
          style={{ minWidth: "initial" }}
          onClick={a.toggleMenuExpanded("folder")}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label="Add to folder"
        >
          {folder && (
            <span style={{ whiteSpace: "nowrap" }}>{folder.name}&nbsp;</span>
          )}
          {note.folderId ? <FolderIcon /> : <FolderNoFillIcon />}
          <ChevronDownIcon />
        </button>
        <button
          className={`note-menu-button ${
            note.positionInPinned ? "active" : ""
          }`}
          onClick={a.togglePin}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label={`${note.positionInPinned ? "Unpin note" : "Pin note"}`}
        >
          {note.positionInPinned ? <UnpinIcon /> : <PinIcon />}
        </button>
        <button
          className="note-menu-button"
          onClick={a.openAsPage}
          title="Open as Page"
          role="tooltip"
          data-microtip-position="bottom"
          aria-label="Open as page"
        >
          <ExpandIcon />
        </button>
        <button
          className={`note-menu-button ${
            a.menuExpanded() === "more" ? "expanded" : ""
          }`}
          onClick={a.toggleMenuExpanded("more")}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label="More actions"
        >
          <MoreIcon />
        </button>
      </div>
      {a.menuExpanded() === "folder" ? (
        <FolderList
          note={note}
          forceUpdate={a.forceUpdate}
          closeMenu={() => a.toggleMenuExpanded(null)()}
        />
      ) : (
        ""
      )}
      {a.menuExpanded() === "more" ? <More note={note} actions={a} /> : ""}
      {/* a full screen interactive zone to close the menu when clicking outside. */}
      {a.menuExpanded() && (
        <Background
          onClick={() => {
            a.toggleMenuExpanded(null)();
          }}
        />
      )}
    </DropMenuContainer>
  );
};

export { NoteMenu };
