import { useCallback, useEffect, useState } from "react";
import { atom, useSetAtom } from "jotai";
import { Note } from "../../model/types";
import {
  noteFormatter,
  noteList,
  pinnedNotePositions,
} from "../../model/services";
import { useSearchState } from "../../search/searchStateAtom";
import { useNotifySidebarUpdate } from "../../sidebar/atoms/sidebarUpdate";
import { toast } from "./toast";
import { copyTextToClipboard } from "../../export/copyToClipboard";
import { parseInsertedAt } from "../utils/insertedAt";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { toUrl } from "../../search/SearchQuery";

export const menuUpdate = atom(0);

// Shared actions between the mobile and desktop menu.
export const useMenuActions = (
  note: Note,
  closeMenu: () => void,
  setShouldStayVisible = (_: boolean) => {},
) => {
  const [, setSearchQuery] = useSearchState();

  const update = useSetAtom(menuUpdate);
  const forceUpdate = useCallback(() => update((x) => x + 1), [update]);

  const updateSidebar = useNotifySidebarUpdate();
  const [menuExpanded, setMenuExpanded] = useState<"folder" | "more" | null>(
    null,
  );
  // reset menu expansion when hovering another noteId.
  useEffect(() => setMenuExpanded(null), [note.id]);

  // toggle menu expansion
  const toggleMenuExpanded = useCallback(
    (menu: "folder" | "more" | null) => () => {
      // when we click on a menu already expanded, we want to the menu to be able to disappear again
      // and if we open a new menu, we want it to stay visible.
      setShouldStayVisible(!!menu && menu !== menuExpanded);
      setMenuExpanded((m) => (m !== menu ? menu : null));
      forceUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note, setMenuExpanded, setShouldStayVisible, menuExpanded, forceUpdate],
  );

  // pin
  const togglePin = useCallback(() => {
    const positionInPinned = note.positionInPinned
      ? null
      : pinnedNotePositions.generateFirst()[0];
    noteList.update({ id: note.id, positionInPinned });
    forceUpdate();
    updateSidebar();
    closeMenu();
    toast("Note pinned!");
    trackEvent(["NOTE", "TOGGLED_PINNED"]);
  }, [forceUpdate, note, updateSidebar]);

  // open as page
  const openAsPage = useCallback(() => {
    setSearchQuery({
      type: "note",
      q: note.id,
      asPage: true,
    });
    closeMenu();
    setMenuExpanded(null);
    trackEvent(["NOTE", "OPENED_AS_PAGE"]);
  }, [note.id, closeMenu, setSearchQuery]);

  const togglePublish = () => {
    noteList.update({ id: note.id, readAll: !note.readAll });
    closeMenu();
    forceUpdate();
  };
  const share = () => {
    if (!note.readAll) {
      togglePublish();
    }
    // clipboard is not set in unit tests
    if (!(navigator as any).clipboard) return;
    (navigator as any).clipboard.writeText(
      window.location.protocol +
        "//" +
        window.location.host +
        "/shared/" +
        note.id,
    );
    updateSidebar();
    setMenuExpanded(null);
    closeMenu();
    toast("Sharing link copied to clipboard!");
  };

  const goToDay = () => {
    setSearchQuery({ type: "date", q: parseInsertedAt(note.insertedAt) });
    setMenuExpanded(null);
    forceUpdate();
    closeMenu();
  };

  const copy = () => {
    const noteContent = noteFormatter.getNoteAsStrings(note.tokens).join("\n");
    closeMenu();
    copyTextToClipboard(noteContent);
    setMenuExpanded(null);
    toast("Note copied to clipboard!");
    trackEvent(["NOTE", "COPY_TEXT"]);
  };

  const copyUrl = () => {
    const url = toUrl({ type: "note", q: note.id, asPage: true });
    closeMenu();
    copyTextToClipboard(url.toString());
    setMenuExpanded(null);
    toast("URL copied to clipboard!");
    trackEvent(["NOTE", "COPY_URL"]);
  };

  const jump = () => {
    setSearchQuery({
      type: "note",
      q: note.id,
      asPage: false,
    });
    closeMenu();
    forceUpdate();
    setMenuExpanded(null);
  };

  return {
    toggleMenuExpanded,
    togglePin,
    togglePublish,
    openAsPage,
    share,
    copy,
    copyUrl,
    goToDay,
    jump,
    menuExpanded: () => menuExpanded,
    forceUpdate,
  };
};
