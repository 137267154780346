import { RefObject, useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

type Position = { x: number; y: number };

function offsetBBox(bbox: DOMRect, offset: Position): DOMRect {
  return {
    ...bbox,
    top: bbox.top - offset.y,
    bottom: bbox.bottom - offset.y,
    left: bbox.left - offset.x,
    right: bbox.right - offset.x,
  };
}

// Returns the hovered element matching the selector.
//
// It is useful when you have elements that you cannot attach callbacks on,
// for instance a virtual list with lots of elements.
// This function gets the element under the cursor,
// then find the closest parent that matches the selector.
//
// The selector helps in limiting the number of rerenders, as it triggers a rerender only
// when the cursor switches at the specified level in the DOM structure.
//
// It runs faster once built, as React removes quite a few debugging hooks on the mousemove event.
//
// It also runs faster if the elements matching the selector cannot overlap,
// as we can save us from running the expensive tracing function elementFromPoint
// when the cursor is still in the bounding rect of the preivously hovered element.
export const useHoveredEmulation = (
  selector: string,
  parentRef: RefObject<HTMLDivElement>,
  canOverlap = false,
) => {
  // we cache the bounding rect of the currently hovered element, as elementFromPoint is costly.
  const _isIn = ({ x, y }: Position, b: DOMRect) => {
    return y >= b.top && y < b.bottom && x >= b.left && x < b.right;
  };
  // the element matching the selector.
  // memo the bounding rect for optimization purposes.
  const [elementWithBBox, setElementWithBBox] = useState<{
    element: Element;
    relativeBBox: DOMRect;
  } | null>(null);

  const getParentOffset = useCallback(() => {
    return (
      parentRef.current?.getBoundingClientRect() ?? {
        x: 0,
        y: 0,
      }
    );
  }, [parentRef]);

  const computeBBoxAndSetElement = useCallback(
    (element: Element | null) => {
      // Consider the elements removed from DOM as unhoverable
      if (!element || !document.contains(element)) {
        setElementWithBBox(null);
        return;
      }
      const relativeBBox = offsetBBox(
        element.getBoundingClientRect(),
        getParentOffset(),
      );
      setElementWithBBox({ element, relativeBBox });
    },
    [setElementWithBBox, getParentOffset],
  );

  const onMouseMove = useDebouncedCallback(
    (e: MouseEvent | WheelEvent) => {
      const parentOffset = getParentOffset();
      const clientPos = { x: e.x, y: e.y };
      const relativePos = { x: e.x - parentOffset.x, y: e.y - parentOffset.y };
      // when elements cannot overlap, if its still in the bounding box of the previous element,
      // we consider that the previous element is still the right one.
      if (
        !canOverlap &&
        elementWithBBox?.relativeBBox &&
        _isIn(relativePos, elementWithBBox.relativeBBox)
      ) {
        return;
      }
      const hovered = document.elementFromPoint(clientPos.x, clientPos.y); // trace which element is visible under the cursor position.
      const matching = hovered?.closest(selector) || null;
      computeBBoxAndSetElement(matching);
    },
    100, // felt like a good compromise speed/battery.
    { maxWait: 100 },
  );

  useEffect(() => {
    if (!elementWithBBox?.element) return;

    const resizeObserver = new ResizeObserver(() => {
      computeBBoxAndSetElement(elementWithBBox.element);
    });
    resizeObserver.observe(elementWithBBox.element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [elementWithBBox?.element, parentRef, computeBBoxAndSetElement]);

  useEffect(() => {
    const ref = parentRef.current;
    ref?.addEventListener("mousemove", onMouseMove.callback, {
      passive: true,
    });
    return () => {
      ref?.removeEventListener("mousemove", onMouseMove.callback);
      onMouseMove.cancel();
    };
  }, [parentRef, onMouseMove]);
  return elementWithBBox;
};
