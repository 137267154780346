import { atom, useAtom } from "jotai";
import {
  SearchQuery,
  toParsedUrl,
  sortByDefault,
  SortBy,
  fromParsedUrl,
  partialToSearchQuery,
} from "./SearchQuery";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { isClientSide } from "../utils/environment";
import { useEditorScrollPositions } from "../editorPage/EditorWithAutocomplete";
import { atomWithStorage } from "jotai/utils";

function atomWithTypesafeStorage<T>(
  key: string,
  initialValue: T,
  mapGet: (v: string | null) => T,
  mapSet: (v: T) => string = (v) => v + "",
) {
  return atomWithStorage<T>(key, initialValue, {
    getItem(key) {
      return mapGet(localStorage.getItem(key));
    },
    setItem(key, value) {
      localStorage.setItem(key, mapSet(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  });
}

const searchIsCondensedAtom = isClientSide
  ? atomWithTypesafeStorage<boolean>(
      "search-is-condensed",
      true,
      (v) => v === "true",
    )
  : atom(false);

const IsIncludingSubFoldersAtom = isClientSide
  ? atomWithTypesafeStorage<boolean>(
      "search-is-including-sub-folders",
      true,
      (v) => v === "true",
    )
  : atom(true);

const searchSortByAtom = isClientSide
  ? atomWithTypesafeStorage<SortBy>(
      "search-sort-by",
      sortByDefault,
      (v) => Object.values(SortBy).find((s) => s === v) ?? sortByDefault,
    )
  : atom(sortByDefault);

export const useSearchState = () => {
  const router = useRouter();
  const [isCondensed, setIsCondensed] = useAtom(searchIsCondensedAtom);
  const [isIncludingSubFolders, setIsIncludingSubFolders] = useAtom(
    IsIncludingSubFoldersAtom,
  );
  const [sortBy, setSortBy] = useAtom(searchSortByAtom);
  const [, setScrollPosition] = useEditorScrollPositions();

  const setSearchQuery = useCallback(
    (state: Partial<SearchQuery> | null) => {
      // it should be a searchQuery with optional isCondensed.
      if (state == null) {
        router.push({ query: null });
        return;
      }

      // For each persisted search property, if a value is given then update
      // the atom with it. Otherwise, set the state to the current value of the atom.
      if (typeof state.isCondensed === "boolean") {
        setIsCondensed(state.isCondensed);
      } else {
        state.isCondensed = isCondensed;
      }
      if (typeof state.isIncludingSubFolders === "boolean") {
        setIsIncludingSubFolders(state.isIncludingSubFolders);
      } else {
        state.isIncludingSubFolders = isIncludingSubFolders;
      }
      if (state.sortBy) {
        setSortBy(state.sortBy);
      } else {
        state.sortBy = sortBy;
      }

      setScrollPosition(state as SearchQuery, 0);
      router.push({
        query: toParsedUrl(partialToSearchQuery(state)),
      });
    },
    [
      router,
      setIsCondensed,
      setSortBy,
      isCondensed,
      sortBy,
      isIncludingSubFolders,
      setIsIncludingSubFolders,
      setScrollPosition,
    ],
  );

  const searchQuery: SearchQuery = useMemo(() => {
    const ret = fromParsedUrl(router.query);
    ret.isCondensed = isCondensed;
    ret.isIncludingSubFolders = isIncludingSubFolders;
    if (ret.sortBy && ret.sortBy !== sortBy) {
      setSortBy(ret.sortBy);
      if (isClientSide) localStorage.setItem("search-sort-by", ret.sortBy);
    } else {
      ret.sortBy = sortBy;
    }
    return partialToSearchQuery(ret);
  }, [router.query, isCondensed, isIncludingSubFolders, sortBy, setSortBy]);

  return [searchQuery, setSearchQuery] as const;
};
