import { useEffect } from "react";
import { folderList, noteFormatter } from "../model/services";
import { RoutesOption } from "../routes";
import { SearchQuery } from "../search/SearchQuery";
import { assertUnreachable } from "../utils/assertUnreachable";

export const useHtmlTitle = (
  route: RoutesOption,
  query: SearchQuery,
  wasInitialLoadDone: boolean,
) => {
  useEffect(() => {
    const pageTitle = getPageTitle(route, query);
    document.title = pageTitle === "" ? "Ideaflow" : `${pageTitle} | Ideaflow`;
    return () => {
      document.title = "Ideaflow";
    };
  }, [route, query, wasInitialLoadDone]);
};

const getPageTitle = (route: RoutesOption, query?: SearchQuery) => {
  if (route === RoutesOption.Settings) return "Settings";

  if (query == null) {
    return "";
  }

  switch (query.type) {
    case "text":
      return `"${query.q}"`;
    case "*":
      return "";
    case "hashtag":
      return query.q;
    case "is":
      switch (query.q) {
        case "complete":
          return "Completed Tasks";
        case "incomplete":
          return "Incomplete Tasks";
        case "public":
          return "Public Notes";
        case "todo":
          return "Tasks";
        default:
          throw assertUnreachable(query);
      }
    case "note":
      return noteFormatter.getNoteEllipsis(query.q); // @todo this shows up as (missing note) after refresh
    case "date":
      return `Notes on ${query.q.toLocaleDateString()}`;
    case "folder":
      return `Folder: ${folderList.get(query.q)?.name}`;
    default:
      throw assertUnreachable(query);
  }
};
