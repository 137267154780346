import { useAtomValue } from "jotai";
import { folderList } from "../model/services";
import { hasHeader, SearchQuery } from "../search/SearchQuery";
import { useSearchState } from "../search/searchStateAtom";
import { FolderIcon } from "../sidebar/img/FolderIcon";
import { assertUnreachable } from "../utils/assertUnreachable";
import { colors } from "../utils/style";
import { isLoadedAtom } from "./atoms/isLoadedAtom";
import { SearchResultsHeader } from "./SearchResultsHeader";

const EditorHeaderFormatter = Intl.DateTimeFormat([], {
  weekday: "long",
  day: "numeric",
  month: "long",
});

function getSearchTitle(query: SearchQuery): string | JSX.Element | null {
  switch (query.type) {
    case "*":
    case "text":
    case "note":
      return null;
    case "is": {
      switch (query.q) {
        case "public":
          return "Publicly Shared Notes";
      }
      return null;
    }
    case "hashtag":
      return query.q;
    case "date":
      return EditorHeaderFormatter.format(query.q);
    case "folder": {
      if (!folderList.has(query.q)) {
        return null;
      }
      return (
        <>
          <FolderIcon size={28} color={colors.text.secondary} />
          <span style={{ paddingLeft: 4 }} />
          <span>{folderList.get(query.q)!.name}</span>
        </>
      );
    }
    default:
      throw assertUnreachable(query);
  }
}

export const EditorHeader = ({
  searchResultsCount,
}: {
  searchResultsCount: number;
}) => {
  const [searchQuery, setSearchQuery] = useSearchState();
  // Subscribe to the isLoadedAtom to regenerate the editor header once the data is loaded
  useAtomValue(isLoadedAtom);
  const title = getSearchTitle(searchQuery);

  if (searchQuery.type === "*") {
    return null;
  }

  return (
    <div
      className="editorHeader"
      style={{
        width: "calc(100% - 48px)",
        maxWidth: 720,
        marginTop: 16,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: -6,
        zIndex: 1,
      }}
    >
      {hasHeader(searchQuery) && (
        <SearchResultsHeader
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          searchResultsCount={searchResultsCount}
        />
      )}
      {title && (
        <h1
          style={{
            margin: 0,
            marginTop: 6,
            fontWeight: "normal",
            fontSize: "2rem",
            display: "flex",
            alignItems: "center",
            color: "var(--color-text-strong)",
          }}
        >
          {title}
        </h1>
      )}
    </div>
  );
};
