import { NoteId } from "../model/types";
import { CSSProperties, useEffect, useRef, useMemo } from "react";
import {
  noteSearcher,
  noteList,
  noteBlockMatches,
  expansionSet,
} from "../model/services";
import React from "react";
import { createEditorView, EditorViewConfig } from "../editor/editorView";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { shortcuts } from "../shortcuts/shortcuts";
import { assertUnreachable } from "../utils/assertUnreachable";
import { useSearchState } from "../search/searchStateAtom";
import { messageAtom } from "../modal/messageAtom";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";
import {
  useRefreshWithEditorUpdate,
  useUpdateEditor,
} from "./atoms/editorUpdate";
import {
  EditorState,
  TextSelection,
  Transaction,
  Selection,
} from "prosemirror-state";
import { SearchQuery, searchQueryToPage } from "../search/SearchQuery";
import { useHtmlTitle } from "./useHtmlTitle";
import { NoResults } from "./NoResults";
import { useCreateNote, wasAtLeastOneNoteAddedAtom } from "./useCreateNote";
import { RoutesOption } from "../routes";
import {
  DEFAULT_PARAGRAPH_LIMIT,
  PARAGRAPH_LIMIT_INCREMENT,
} from "../search/Searcher";
import { isLoadedAtom } from "./atoms/isLoadedAtom";
import { NoteMenu } from "./noteMenu/NoteMenu";
import { mobileNoteIdMenuAtom } from "./noteMenu/MobileNoteIdMenuAtom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  scrollEditorToNote,
  scrollEditorToPosition,
  useEditorScrollPositions,
} from "./EditorWithAutocomplete";
import { trackEvent } from "../analytics/analyticsHandlers";
import { editorViewAtom } from "./atoms/editorViewAtom";
import { useRouter } from "next/dist/client/router";
import { generatePathFromNoteId } from "../editor/utils/path";
import { getParentNote } from "../editor/utils/find";
import { descendNotes } from "../editor/utils/descendNotes";
import debug from "debug";
import { NoteInsert, NoteUpdates } from "../model/noteList";
import { Spinner } from "../utils/Spinner";
import { useAuthenticatedServerFetch } from "../auth/AccessTokenManager";
import { isContinuousIntegration } from "../utils/environment";
import { isSidebarOpenAtom } from "../sidebar/atoms/isSidebarOpenAtom";

type EditorProps = {
  setSearchResultsCount: (count: number) => void;
  styles?: CSSProperties;
} & Pick<EditorViewConfig, "setAutocompleteState" | "autocompleteKeyDownRef">;

export type DispatchToModel = (
  action:
    | { type: "delete"; payload: NoteId[] }
    | { type: "refreshSidebar" }
    | { type: "insert"; payload: NoteInsert[] }
    | { type: "update"; payload: NoteUpdates[] }
    | { type: "toggle-menu"; payload: { id: NoteId } }
    | { type: "goto"; payload: { id: NoteId } }
    | { type: "gotoday"; payload: { date: Date } },
) => void;

/**
 * Note selection position is tracked using pairs of note path and offset
 * so that inserting/deleting new notes in the note list will not affect positions.
 */
type NoteSelection = {
  fromNotePath: NoteId | null;
  toNotePath: NoteId | null;
  fromOffset: number;
  toOffset: number;
};

export function Editor({
  setSearchResultsCount,
  ...otherEditorArgs
}: EditorProps) {
  const router = useRouter();
  const setMessage = useSetAtom(messageAtom);
  const setEditorView = useSetAtom(editorViewAtom);
  const [searchQuery, setSearchQuery] = useSearchState();
  const latestNetworkEdit = useRefreshWithEditorUpdate();
  const updateSidebar = useNotifySidebarUpdate();
  const wasInitialLoadDone = useAtomValue(isLoadedAtom);
  const [getScrollPosition] = useEditorScrollPositions();
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const authenticatedServerFetch = useAuthenticatedServerFetch();

  const setNoteIdMenu = useSetAtom(mobileNoteIdMenuAtom);

  const createNote = useCreateNote();

  useHotkeys(shortcuts.createNewNote, () => createNote());
  useHotkeys(shortcuts.createNewNoteDesktop, () => createNote());
  useHotkeys(shortcuts.createNewPage, () => router.push("/new"));
  useHotkeys(shortcuts.backToAllNotes, () => {
    setSearchQuery({ type: "*" });
    trackEvent(["GO", "HOME_FROM_SHORTCUT"]);
  });
  useHotkeys(shortcuts.toggleSidebar, () => setShowSidebar((v) => !v));

  const { notes: initialNotes, skippedNotesCount } = useMemo(() => {
    return noteSearcher.searchNotes(searchQuery);
    // we want to refresh the search when there is a network update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, latestNetworkEdit]);

  // Reset the noteBlockMatches map on every search
  useEffect(() => {
    expansionSet.clear();
    noteBlockMatches.clear();
    initialNotes.forEach((note) => {
      const path = generatePathFromNoteId(note.entry.id);
      noteBlockMatches.set(path, note.matches || []);
    });
  }, [initialNotes]);

  useEffect(() => {
    setSearchResultsCount(initialNotes.length + skippedNotesCount);
  }, [initialNotes, setSearchResultsCount, skippedNotesCount]);

  useHtmlTitle(RoutesOption.Home, searchQuery, wasInitialLoadDone);

  const editorDiv = useRef<HTMLDivElement>(null);

  const [wasAtLeastOneNoteAdded, setWasAtLeastOneNoteAdded] = useAtom(
    wasAtLeastOneNoteAddedAtom,
  );

  // Sometimes the app must re-render the editor (e.g. after receiving new
  // checkpoint changes) while the user is focused on the editor. We want to
  // keep the selection/cursor position consistent between these renders, so we
  // keep track of this state in a Ref (so that updating it does not cause yet
  // more re-renders) and then restore after render.
  const selectionPersistenceRef = useRef<NoteSelection | null>(null);
  const lastSearchQuery = useRef<SearchQuery | null>(null);

  useEffect(() => {
    if (editorDiv.current == null || initialNotes == null) {
      // not initialized.
      return;
    }

    setWasAtLeastOneNoteAdded(false);

    const dispatchToModel: DispatchToModel = (action) => {
      switch (action.type) {
        case "refreshSidebar": {
          updateSidebar();
          return;
        }
        case "insert": {
          setWasAtLeastOneNoteAdded(true);
          return noteList.insert(action.payload);
        }
        case "update": {
          return noteList.update(action.payload);
        }
        case "delete": {
          return noteList.delete(action.payload);
        }
        case `toggle-menu`: {
          setNoteIdMenu(action.payload.id);
          return;
        }
        case "goto": {
          setSearchQuery({
            type: "note",
            q: action.payload.id,
            asPage: false,
          });
          return;
        }
        case "gotoday": {
          setSearchQuery({
            type: "date",
            q: action.payload.date,
          });
          return;
        }
        default:
          assertUnreachable(action);
      }
    };

    const noteToFocusAtInit =
      searchQuery.type === "note" && !searchQuery.asPage ? searchQuery.q : null;
    const editor = createEditorView({
      initialNotes,
      div: editorDiv.current,
      isReadOnly: false,
      dispatchToModel,
      noteToFocusAtInit,
      searchQuery,
      setSearchQuery,
      setMessage,
      ...otherEditorArgs,
      authenticatedServerFetch,
    });

    // Set selection and scroll after editor change
    const tr = editor.view.state.tr;
    // With the following line we ask the autocomplete plugin to search for the match
    // even though no document change occured (https://linear.app/ideaflow/issue/ENT-1737/)
    tr.setMeta("reapplyAutocompletePlugin", true);
    const prevPos = getScrollPosition(searchQuery);
    /** Whether the new query is the same page (ignores props like limit and sorting)*/
    const samePage =
      lastSearchQuery.current &&
      searchQueryToPage(lastSearchQuery.current) ===
        searchQueryToPage(searchQuery);
    if (lastSearchQuery.current === searchQuery) {
      // If the last query and current query are the same object, then the
      // the render wasn't from a search query change, and so must
      // have been from a network edit (see {@link latestNetworkEdit}).
      // Keep user's focus on the same note after a network edit
      setNoteSelection(tr, selectionPersistenceRef.current);
      tr.scrollIntoView();
      editor.view.focus();
    } else if (searchQuery.type === "note" && !searchQuery.asPage) {
      // User selected "show in timeline", so scroll to the note
      scrollEditorToNote(searchQuery.q);
    } else if (
      samePage &&
      lastSearchQuery.current?.limit !== searchQuery.limit
    ) {
      // User pressed "load more" button, so leave the scroll position as is
    } else {
      // Otherwise, scroll to the last saved scroll position.
      // If setSearchQuery was used, it sets the scroll position to 0,
      // so this will scroll to the top of the page. But if setSearchQuery wasn't used
      // (e.g. if history navigation was used) then the scroll position will be the
      // user's position last time they were on the page.
      scrollEditorToPosition(prevPos);
      if (searchQuery.type === "note" && searchQuery.asPage) {
        // If user opened a note as a page, maintain their selection
        const wasSet = setNoteSelection(tr, selectionPersistenceRef.current);
        if (!wasSet) {
          // If there's no saved selection for the note, set to end of first line
          const pos = tr.doc.nodeAt(0)?.firstChild?.nodeSize || 0;
          const sel = Selection.findFrom(tr.doc.resolve(pos), 1, true);
          if (sel) tr.setSelection(sel);
        }
        editor.view.focus();
      }
    }
    editor.view.dispatch(tr);
    setEditorView(editor.view);

    return () => {
      if (!editor) return;
      selectionPersistenceRef.current = getNoteSelection(editor.view.state);
      lastSearchQuery.current = searchQuery;
      editor.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialNotes, editorDiv]);

  const updateEditor = useUpdateEditor();
  if (isContinuousIntegration) {
    window.refreshEditor = () => {
      updateEditor();
    };
  }

  const isNoResult =
    !wasAtLeastOneNoteAdded && initialNotes && initialNotes.length === 0;

  return (
    <>
      {!wasInitialLoadDone && <LoadingMessage />}
      {wasInitialLoadDone && isNoResult && (
        <NoResults searchQuery={searchQuery} createNewNote={createNote} />
      )}
      <div
        key="editorDiv"
        ref={editorDiv}
        className={`editor-div ProseMirror loaded 
        ${
          searchQuery.type === "text" && searchQuery.isCondensed
            ? "isCollapsable"
            : ""
        }
        ${isNoResult ? "no-result" : "result"} 
        ${initialNotes ? "not-initial" : "initial"}
        `}
        style={{ padding: "24px" }}
      />

      <NoteMenu editorDiv={editorDiv} />
      {skippedNotesCount > 0 && (
        <button
          className="show-more-button"
          onClick={() => {
            setSearchQuery({
              ...searchQuery,
              limit:
                (searchQuery.limit ?? DEFAULT_PARAGRAPH_LIMIT) +
                PARAGRAPH_LIMIT_INCREMENT,
            });
          }}
        >
          Load more notes ({skippedNotesCount} hidden)
        </button>
      )}
    </>
  );
}

function LoadingMessage() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <Spinner style={{ width: 30, height: 30, margin: 5 }} />
      <span style={{ fontStyle: "italic" }}>loading...</span>
    </div>
  );
}

function getNoteSelection(state: EditorState): NoteSelection | null {
  if (!(state.selection instanceof TextSelection)) {
    // Selection in the general case is much harder to restore correctly, so only restore TextSelection
    return null;
  }
  const { $from, $to } = state.selection;
  const [fromNote] = getParentNote($from.doc, $from.pos);
  const [toNote] = getParentNote($to.doc, $to.pos);
  const fromNotePath = fromNote?.attrs.path;
  const toNotePath = toNote?.attrs.path;
  if (!fromNotePath || !toNotePath) return null;

  const fromOffset = $from.pos - $from.start(1) + 1;
  const toOffset = $to.pos - $to.start(1) + 1;

  return {
    fromNotePath,
    toNotePath,
    fromOffset,
    toOffset,
  };
}

/**
 * Set note selection on transaction and return whether it was set
 */
function setNoteSelection(
  tr: Transaction,
  selection: NoteSelection | null,
): boolean {
  if (!selection) return false;
  let from: number | null = null;
  let to: number | null = null;
  if (selection) {
    const { fromNotePath, toNotePath, fromOffset, toOffset } = selection;
    descendNotes(tr.doc, (node, pos) => {
      if (from !== null && to !== null) return false;
      if (node.attrs.path === fromNotePath) {
        from = pos + fromOffset;
      }
      if (node.attrs.path === toNotePath) {
        to = pos + toOffset;
      }
    });
  }
  if (from !== null && to !== null) {
    // TextSelection.create() can throw if {from, to} is out of range of
    // the new note. Wrapping this in a try-catch is simpler than trying
    // to check the positions manually.
    try {
      tr.setSelection(TextSelection.create(tr.doc, from, to));
      return true;
    } catch (e) {
      debug("editor")(
        "Could not restore cursor selection position after editor re-render.",
      );
    }
  }
  return false;
}
