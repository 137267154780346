import { atom, useAtom, useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { trackEvent } from "../analytics/analyticsHandlers";
import { useSearchState } from "../search/searchStateAtom";

const navigationHistoryAtom = atom<string[]>([]);
const navigationFromAppAtom = atom(false);

/**
 * A hook to go to the home page.
 * @throw Error if the navigation history hasn't been initialized with {@link useInitNavigationHistory}
 */
export const useHome = () => {
  const [navigationHistory, setNavigationHistory] = useAtom(
    navigationHistoryAtom,
  );
  if (navigationHistory.length === 0) {
    throw new Error(
      "Tried calling useHome while navigation history is empty. Did you forget to call useNavigationHistory?",
    );
  }
  const setSearchQuery = useSearchState()[1];
  const setNavigationFromApp = useSetAtom(navigationFromAppAtom);
  return useCallback(() => {
    setSearchQuery({ type: "*" });
    setNavigationFromApp(true);
    setNavigationHistory([window.history.state.key]);
  }, [setSearchQuery, setNavigationHistory, setNavigationFromApp]);
};

/**
 * A hook to go back in the navigation history. If the user is on the first
 * page in the history, go to the home page instead.
 * @throw Error if the navigation history hasn't been initialized with {@link useInitNavigationHistory}
 */
export const useBack = () => {
  const [navigationHistory] = useAtom(navigationHistoryAtom);
  if (navigationHistory.length === 0) {
    throw new Error(
      "Tried calling useBack while navigation history is empty. Did you forget to call useNavigationHistory?",
    );
  }
  const setNavigationFromApp = useSetAtom(navigationFromAppAtom);
  const home = useHome();
  const router = useRouter();
  return useCallback(() => {
    if (navigationHistory.length <= 1) return home();
    router.back();
    setNavigationFromApp(true);
  }, [navigationHistory, home, router, setNavigationFromApp]);
};

/**
 * Initialize navigation history
 *
 * This is used in conjunction with the `useBack` hooks to differentiate between
 * the user going back using the browser back button and the app back button.
 *
 * Navigation history is a stack of visited pages, including the current page,
 * where each page is represented by their associated `window.history.state.key`
 * value.
 *
 * When the user navigates to a new page or uses the browser forward button, add
 * it to the history stack. When the user uses the browser back button, remove
 * the last page from the history stack.
 * */
export const useInitNavigationHistory = () => {
  const router = useRouter();
  const [navigationHistory, setNavigationHistory] = useAtom(
    navigationHistoryAtom,
  );
  const [historyFromApp, setHistoryFromApp] = useAtom(navigationFromAppAtom);

  // Initialize history with the current page
  if (navigationHistory.length === 0) {
    setNavigationHistory([window.history.state.key]);
  }

  // Update history when the user navigates to a new page
  useEffect(() => {
    const updateNavigationHistory = () => {
      setNavigationHistory((history) => {
        const current = window.history.state.key;
        if (current === history[history.length - 2]) {
          return history.slice(0, -1); // back
        } else if (current === history[history.length - 1]) {
          return history; // noop
        } else {
          return [...history, current]; // forward or new page
        }
      });
    };
    router.events.on("routeChangeComplete", updateNavigationHistory);
    return () => {
      router.events.off("routeChangeComplete", updateNavigationHistory);
    };
  }, [router, setNavigationHistory]);

  // Track when the user uses the browser history button
  useEffect(() => {
    const handlePopState = () => {
      if (!historyFromApp) {
        trackEvent(["GO", "HISTORY_FROM_BROWSER"]);
      }
      setHistoryFromApp(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [historyFromApp, setHistoryFromApp]);
};
