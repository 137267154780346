import styled from "@emotion/styled";
import { trackEvent } from "../analytics/analyticsHandlers";
import { colors } from "../utils/style";
import { HomeButton } from "./HomeButton";
import { SearchButton } from "./SearchButton";
import { ToggleSideBarButton } from "./ToggleSideBarButton";

export const footerHeight = 50;
const buttonSize = 25;

const Container = styled.div`
  display: flex;
  border-top: 1px solid ${colors.border.secondary};
  height: ${footerHeight}px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
`;

export const Footer = () => {
  return (
    <Container>
      <ToggleSideBarButton
        callback={() => trackEvent(["SIDEBAR", "TOGGLE_FROM_FOOTER"])}
        size={buttonSize}
      />
      <HomeButton
        callback={() => trackEvent(["GO", "HOME_FROM_FOOTER"])}
        size={buttonSize}
      />
      <SearchButton
        callback={() => trackEvent(["SEARCH", "FOCUS_FROM_FOOTER"])}
        size={buttonSize}
      />
    </Container>
  );
};
