import styled from "@emotion/styled";
import { Toggle } from "./Toggle";
import { SearchResultsSortMenu } from "./SearchResultsSortMenu";
import { isGreaterThanMobileWidth } from "../utils/environment";
import { isCondensable, SearchQuery, SortBy } from "../search/SearchQuery";
import { colors } from "../utils/style";
import { trackEvent } from "../analytics/analyticsHandlers";
import { assertUnreachable } from "../utils/assertUnreachable";
import { folderList } from "../model/services";

function SearchResultsCount({ count }: { count: number }) {
  const Text = styled.div`
    margin-left: 8px;
    font-size: 14px;
    color: ${colors.text.secondary};
  `;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text>{count.toLocaleString()} results</Text>
    </div>
  );
}

function SearchResultsControls({
  setSearchQuery,
  searchQuery,
}: {
  setSearchQuery: (search: SearchQuery) => void;
  searchQuery: SearchQuery;
}) {
  function sortByToEvent(sortBy: SortBy) {
    switch (sortBy) {
      case SortBy.relevance:
        return "SORT_BY_RELEVANCE";
      case SortBy.lastUpdated:
        return "SORT_BY_LAST_UPDATED";
      case SortBy.position:
        return "SORT_BY_POSITION";
      default:
        assertUnreachable(sortBy);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <SearchResultsSortMenu
        label={isGreaterThanMobileWidth ? "Sort by" : ""}
        currentValue={searchQuery.sortBy}
        setValue={(sortBy: any) => {
          setSearchQuery({
            ...searchQuery,
            sortBy,
          });
          const event = sortByToEvent(sortBy);
          if (event) trackEvent(["FILTER", event]);
        }}
      />
      {isCondensable(searchQuery) && (
        <Toggle
          id="compact-view-toggle"
          label={isGreaterThanMobileWidth ? "Compact view" : "Compact"}
          isChecked={!!searchQuery.isCondensed}
          onClick={() => {
            const isCondensed = !searchQuery.isCondensed;
            setSearchQuery({
              ...searchQuery,
              isCondensed,
            });
            trackEvent([
              "FILTER",
              isCondensed ? "COMPACT_VIEW_ON" : "COMPACT_VIEW_OFF",
            ]);
          }}
        />
      )}
      {searchQuery.type === "folder" &&
        folderList.hasSubfolder(searchQuery.q) && (
          <Toggle
            id="subfolders-toggle"
            label="Subfolders"
            isChecked={!!searchQuery.isIncludingSubFolders}
            onClick={() => {
              const isIncludingSubFolders = !searchQuery.isIncludingSubFolders;
              setSearchQuery({
                ...searchQuery,
                isIncludingSubFolders,
              });
              trackEvent([
                "FILTER",
                isIncludingSubFolders
                  ? "WITHSUBFOLDERS_VIEW_ON"
                  : "WITHSUBFOLDERS_VIEW_OFF",
              ]);
            }}
          />
        )}
    </div>
  );
}

export function SearchResultsHeader({
  setSearchQuery,
  searchQuery,
  searchResultsCount,
}: {
  setSearchQuery: (search: SearchQuery) => void;
  searchQuery: SearchQuery;
  searchResultsCount: number;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <SearchResultsCount count={searchResultsCount} />
      <SearchResultsControls
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      />
    </div>
  );
}
