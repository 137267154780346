export const PinIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="0.1"
      d="M10.5 3V6.75C10.5 7.59 10.7775 8.37 11.25 9H6.75C7.2375 8.355 7.5 7.575 7.5 6.75V3H10.5ZM12.75 1.5H5.25C4.8375 1.5 4.5 1.8375 4.5 2.25C4.5 2.6625 4.8375 3 5.25 3H6V6.75C6 7.995 4.995 9 3.75 9V10.5H8.2275V15.75L8.9775 16.5L9.7275 15.75V10.5H14.25V9C13.005 9 12 7.995 12 6.75V3H12.75C13.1625 3 13.5 2.6625 13.5 2.25C13.5 1.8375 13.1625 1.5 12.75 1.5Z"
      fill="currentColor"
    />
  </svg>
);

export const UnpinIcon = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.5H8.5V2V5.75C8.5 7.10035 9.4684 8.22036 10.75 8.45485V9H6.7275H6.2275V9.5V14.5429L5.9775 14.7929L5.7275 14.5429V9.5V9H5.2275H1.25V8.45485C2.5316 8.22036 3.5 7.10035 3.5 5.75V2V1.5H3H2.25C2.11364 1.5 2 1.38636 2 1.25C2 1.11364 2.11364 1 2.25 1H9.75C9.88636 1 10 1.11364 10 1.25C10 1.38636 9.88636 1.5 9.75 1.5H9Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export const ExpandIcon = () => (
  <svg
    width="18"
    stroke="currentColor"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 2.25H15.75V6.75"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 15.75H2.25V11.25"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 2.25L10.5 7.5"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 15.75L7.5 10.5"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FolderNoFillIcon = () => (
  <svg
    className="icon-folder"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ fill: "none" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 12.6667C14.6666 13.0203 14.5262 13.3594 14.2761 13.6095C14.0261 13.8595 13.6869 14 13.3333 14H2.66665C2.31302 14 1.97389 13.8595 1.72384 13.6095C1.47379 13.3594 1.33331 13.0203 1.33331 12.6667V3.33333C1.33331 2.97971 1.47379 2.64057 1.72384 2.39052C1.97389 2.14048 2.31302 2 2.66665 2H5.99998L7.33331 4H13.3333C13.6869 4 14.0261 4.14048 14.2761 4.39052C14.5262 4.64057 14.6666 4.97971 14.6666 5.33333V12.6667Z"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>
);

export const FolderIcon = () => (
  <svg
    className="icon-folder"
    width="16"
    height="16"
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6668 10.6667C13.6668 11.0203 13.5264 11.3594 13.2763 11.6095C13.0263 11.8595 12.6871 12 12.3335 12H1.66683C1.31321 12 0.974069 11.8595 0.72402 11.6095C0.473972 11.3594 0.333496 11.0203 0.333496 10.6667V1.33333C0.333496 0.979711 0.473972 0.640573 0.72402 0.390524C0.974069 0.140476 1.31321 0 1.66683 0H5.00016L6.3335 2H12.3335C12.6871 2 13.0263 2.14048 13.2763 2.39052C13.5264 2.64057 13.6668 2.97971 13.6668 3.33333V10.6667Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreIcon = () => (
  <svg
    stroke="currentColor"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9.75002C9.41421 9.75002 9.75 9.41423 9.75 9.00002C9.75 8.5858 9.41421 8.25002 9 8.25002C8.58579 8.25002 8.25 8.5858 8.25 9.00002C8.25 9.41423 8.58579 9.75002 9 9.75002Z"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 9.75002C14.6642 9.75002 15 9.41423 15 9.00002C15 8.5858 14.6642 8.25002 14.25 8.25002C13.8358 8.25002 13.5 8.5858 13.5 9.00002C13.5 9.41423 13.8358 9.75002 14.25 9.75002Z"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 9.75002C4.16421 9.75002 4.5 9.41423 4.5 9.00002C4.5 8.5858 4.16421 8.25002 3.75 8.25002C3.33579 8.25002 3 8.5858 3 9.00002C3 9.41423 3.33579 9.75002 3.75 9.75002Z"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronDownIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4.5L6 7.5L9 4.5"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EnterKey = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34766 13.8242C6.62891 13.8242 6.81055 13.6309 6.81055 13.3555C6.81055 13.2207 6.76953 13.1211 6.68164 13.0332L5.43945 11.832L4.60156 11.1348L5.69141 11.1875H11.3516C12.5 11.1875 12.957 10.6777 12.957 9.58203V6.89844C12.957 5.75 12.5 5.29883 11.3516 5.29883H8.93164C8.63867 5.29883 8.45117 5.50977 8.45117 5.77344C8.45117 6.03125 8.63867 6.24219 8.93164 6.24219H11.3516C11.8262 6.24219 12.0078 6.42969 12.0078 6.89844V9.58203C12.0078 10.0566 11.8262 10.2441 11.3516 10.2441H5.69141L4.60156 10.2969L5.43945 9.59961L6.68164 8.39258C6.76953 8.31055 6.81055 8.20508 6.81055 8.07031C6.81055 7.79492 6.62891 7.60742 6.34766 7.60742C6.23047 7.60742 6.0957 7.66016 6.00195 7.75391L3.33008 10.3613C3.22461 10.4609 3.17188 10.584 3.17188 10.7129C3.17188 10.8418 3.22461 10.9648 3.33008 11.0703L6.00195 13.6777C6.0957 13.7656 6.23047 13.8242 6.34766 13.8242Z"
      fill="currentColor"
    />
  </svg>
);
