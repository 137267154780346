import { getShortcutString, shortcuts } from "../shortcuts/shortcuts";
import styled from "@emotion/styled";
import { colors } from "../utils/style";
import { isIOs } from "../utils/environment";
import { useAtom } from "jotai";
import { isSidebarOpenAtom } from "../sidebar/atoms/isSidebarOpenAtom";

const ButtonContainer = styled.div<{ showSidebar: boolean }>(
  (props) => `
  /* override microtip styles */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 44px;
  min-height: 44px;
  box-sizing: content-box;
  color: ${colors.text.secondary};
  ${props.showSidebar ? `max-width: 284px; flex-shrink: 0;` : ""}
  svg { flex-shrink: 0; }
  ${isIOs ? "outline: 0;" : ""}
`,
);

export const ToggleSideBarButton = ({
  callback,
  size = 20,
}: {
  callback: () => void;
  size?: number;
}) => {
  const [showSidebar, setShowSidebar] = useAtom(isSidebarOpenAtom);
  const tooltip = `${
    showSidebar ? "Hide sidebar " : "Show sidebar "
  } ${getShortcutString(shortcuts.toggleSidebar)}`;

  return (
    <ButtonContainer
      id="toggle-sidebar-button"
      aria-label={tooltip}
      showSidebar={showSidebar}
      tabIndex={0}
      role="tooltip"
      data-microtip-position="right"
      onClick={() => {
        setShowSidebar((v) => !v);
        callback?.();
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33334 2.5H16.6288C17.5702 2.5 18.3333 3.21954 18.3333 4.10714V15.8929C18.3333 16.7805 17.5702 17.5 16.6288 17.5H8.33334"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M1.66666 4.10714C1.66666 3.21954 2.41285 2.5 3.33332 2.5H8.33332V17.5H3.33332C2.41285 17.5 1.66666 16.7805 1.66666 15.8929V4.10714Z"
          fill={showSidebar ? colors.bg.tertiary : "none"}
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </ButtonContainer>
  );
};
