import React, { useEffect } from "react";
import { noteList, notePositions } from "../../../model/services";
import { Suggestion } from "../../../search/SuggestionSearcher";
import { schema } from "../../schema";
import {
  AutoCompleteState,
  useAutocompleteSuggestions,
} from "./useAutocompleteState";
import { useAuth } from "../../../auth/useAuth";
import { NoteId } from "../../../model/types";
import { autocompleteModules, AutocompleteTypes } from "./modules";
import { AUTOCOMPLETE_DECORATION_CLASS } from "./autocompletePlugin";
import { noteToProsemirrorNode } from "../../bridge";
import { useScrollOffsets } from "../../../editorPage/utils/useScrollOffsets";
import { assertUnreachable } from "../../../utils/assertUnreachable";
import { Transaction } from "prosemirror-state";
import useResettableState from "./useResettableState";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import {
  expandOrCollapseSpaceship,
  getLinkedNotePosFromSpaceshipPos,
} from "../reference/referenceExpansionUtils";
import { Selection } from "prosemirror-state";
import { useNotifySidebarUpdate } from "../../../sidebar/atoms/sidebarUpdate";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { useAtomValue } from "jotai";
import { editorViewAtom } from "../../../editorPage/atoms/editorViewAtom";
import debug from "debug";

interface MenuItemProps {
  suggestion: Suggestion;
  isHighlighted: boolean;
  setAsActiveElement: () => void;
  pickIt: (prefereCreate?: boolean) => void;
}

const MenuItem = ({
  suggestion,
  isHighlighted,
  setAsActiveElement,
  pickIt,
}: MenuItemProps) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isHighlighted) {
      divRef.current?.scrollIntoView({ block: "nearest" });
    }
  }, [isHighlighted]);
  return (
    <div
      className={`suggestion-item ${
        suggestion.action ? `suggestion-${suggestion.action}` : ""
      } ${isHighlighted ? "suggestion-item-active" : ""}`}
      onClick={() => pickIt()}
      onMouseOver={() => setAsActiveElement()}
      onMouseOut={() => setAsActiveElement()}
      ref={divRef}
    >
      {suggestion.display ?? suggestion.content}
    </div>
  );
};

/** we return the attrs with an `id` and then a separate `newNoteId` so we
 * can handle creating a new note with the autocomplete
 */
const getNodeToInsertAttrs = (
  pickedSuggestion: Suggestion,
  noteId: NoteId,
  matcherName: AutocompleteTypes,
): {
  attrs: { id: string; content: string } | null;
  newNoteId: string | null;
} => {
  if (!pickedSuggestion.action || pickedSuggestion.action === "insert") {
    return {
      attrs: { id: pickedSuggestion.id, content: pickedSuggestion.content },
      newNoteId: null,
    };
  }
  const content = pickedSuggestion.content;

  switch (matcherName) {
    case "hashtag": {
      return { attrs: { id: content, content }, newNoteId: null };
    }
    case "spaceship": {
      const parent = noteList.get(noteId);
      const [note] = noteList.insert({
        position: parent
          ? notePositions.generateAfter(parent.position)[0]
          : undefined,
        insertedAt: parent?.insertedAt,
        strings: [content],
      });
      trackEvent(["NOTE", "CREATED_FROM_RELATION", note.id]);
      return {
        attrs: { id: note.id, content },
        newNoteId: note.id,
      };
    }
    default: {
      assertUnreachable(matcherName);
      return { attrs: null, newNoteId: null };
    }
  }
};

export const AutocompleteMenu = (props: {
  autocompleteState: AutoCompleteState;
  editorContainerRef: React.RefObject<HTMLDivElement>;
  autocompleteKeyDownRef: React.MutableRefObject<
    ((event: KeyboardEvent, tr: Transaction) => boolean) | null
  >;
}) => {
  const { autocompleteState, editorContainerRef, autocompleteKeyDownRef } =
    props;

  const {
    mostRecentValue: suggestions,
    isValid: areAutocompleteSuggestionsValid,
    computeNow: computeSuggestionsNow,
  } = useAutocompleteSuggestions(autocompleteState);

  const view = useAtomValue(editorViewAtom);
  const { user } = useAuth();
  const currentUserId = user ? user.sub : null;
  // activeElementIndex is reset back to 0 whenever the list of suggestions changes
  const [activeElementIndex, setActiveElementIndex] = useResettableState(0, [
    suggestions,
  ]);

  const updateSidebar = useNotifySidebarUpdate();
  useEffect(() => {
    if (autocompleteState === null) {
      updateSidebar();
    }
  }, [autocompleteState, updateSidebar]);

  autocompleteKeyDownRef.current = (event, _tr) => {
    // nothing to do if the user is not editing an autocomplete entry (as detected by the `autocompletePlugin.ts`)
    if (!autocompleteState) {
      return false;
    }

    if (event.key === "Escape") {
      // The Escape key, if propagated to the document, triggers a navigation
      // back to the home page if the user is at some search page URLs.  We
      // never want Escape to trigger navigation when the autocomplete is
      // open.
      event.stopPropagation();
      autocompleteState.cancel();
      return true;
    }

    // Typing in "#" inside of the hastag match confirms the current hashtag and starts a new one
    if (event.key === "#" && autocompleteState.matcherName === "hashtag") {
      if (!view) return false;

      const tr = view.state.tr;
      tr.insert(tr.selection.to, [
        schema.text(" "),
        schema.text("#", [schema.marks.hashtag.create()]),
      ]);

      view.dispatch(tr);
      return true;
    }

    // user finished inserting a hashtag (with or without autocomplete menu)
    if (
      view &&
      autocompleteState.matcherName === "hashtag" &&
      [" ", "Enter", "Tab"].includes(event.key)
    ) {
      const hashtag = view.state.doc.textBetween(
        autocompleteState.match.from,
        autocompleteState.match.to,
      );
      const count = noteList.hashtags.getNoteCountForItem(hashtag);
      if (count > 0) {
        trackEvent(["HASHTAG", count === 1 ? "CREATED" : "ADDED"]);
      } else {
        debug("editor")(
          `match ${hashtag} is not a hashtag or missing from index`,
        );
      }
    }

    // don't handle if no suggestions
    if (!suggestions.length && areAutocompleteSuggestionsValid) {
      return false;
    }

    switch (event.key) {
      case "ArrowDown":
        setActiveElementIndex((activeElementIndex + 1) % suggestions.length);
        return true;
      case "ArrowUp":
        setActiveElementIndex(
          (activeElementIndex - 1 + suggestions.length) % suggestions.length,
        );
        return true;
      case "Enter":
        return pickIt(event.metaKey || event.ctrlKey);
      case "Tab":
        return pickIt();
    }

    return false;
  };

  const pickIt = (prefereCreate = false): boolean => {
    if (!autocompleteState) return false;

    const validSuggestions = areAutocompleteSuggestionsValid
      ? suggestions
      : computeSuggestionsNow();
    const validActiveElementIndex = areAutocompleteSuggestionsValid
      ? activeElementIndex
      : 0;
    const pickedSuggestion = prefereCreate
      ? validSuggestions.find(
          (s) => s.action === "create" || s.action === "create-and-expand",
        ) ?? validSuggestions[validActiveElementIndex]
      : validSuggestions[validActiveElementIndex];

    console.log("pickedSuggestion", pickedSuggestion);

    // if we don't have a `lastUpdate` since it starts out null,
    // or if the value of the `currentSuggestions` array at the `activeIndex`
    // which is changed with the arrow keys is null, or if the current nodeId
    // since it starts out null and can be null/undefined
    if (!pickedSuggestion || currentUserId == null) {
      return false;
    }
    if (!view) return false;

    const module = autocompleteModules[autocompleteState.matcherName];

    // Since the new note may be added from an expanded note
    // we need to find the root-level note behind which to insert the new one
    const topLevelNoteNode = view.state.doc.nodeAt(
      view.state.selection.$anchor.before(1),
    );
    const topLevelNoteId =
      topLevelNoteNode?.type === schema.nodes.note
        ? topLevelNoteNode.attrs.noteId
        : null;
    const { attrs, newNoteId } = getNodeToInsertAttrs(
      pickedSuggestion,
      topLevelNoteId ?? autocompleteState.noteId,
      autocompleteState.matcherName,
    );
    if (!attrs) return false;

    const tr = view.state.tr;
    tr.setMeta("type", "autocomplete");

    // Insert selected suggestion
    const nodeToInsert = module.createNode(attrs);

    const textAfterMatch = view.state.doc.textBetween(
      autocompleteState.match.to,
      Math.min(view.state.doc.nodeSize - 2, autocompleteState.match.to + 1),
    );

    // We normally want to add a trailing space after an autocomplete to let the
    // user keep typing (iOS keyboard does this). However, if there is whitespace
    // after the cursor already, we don't want to add double / redundant spaces.
    // (For e.g. when user is going back and adding entities to their notes.)
    // see https://linear.app/ideaflow/issue/ENT-437
    const shouldAddTrailingSpace =
      textAfterMatch.trimStart() === textAfterMatch;

    const finalInsert = shouldAddTrailingSpace
      ? [nodeToInsert, schema.text(" ")]
      : nodeToInsert;
    tr.replaceWith(
      autocompleteState.match.from,
      autocompleteState.match.to,
      finalInsert,
    );
    const posAutocompleteNode = autocompleteState.match.from;

    // If a new note was created, insert it after current note
    if (newNoteId) {
      const note = noteList.get(newNoteId)!;
      tr.insert(tr.selection.$anchor.after(1), noteToProsemirrorNode(note));
    }

    // Optionally expand the spaceship and focus on it
    const action = pickedSuggestion.action;
    if (action === "create-and-expand") {
      const posSpaceshipMapped = tr.mapping.map(posAutocompleteNode);
      expandOrCollapseSpaceship(tr, tr.doc.resolve(posSpaceshipMapped));
      const posExpansion = getLinkedNotePosFromSpaceshipPos(
        tr.doc,
        posSpaceshipMapped,
        "start",
      );
      tr.setSelection(Selection.near(tr.doc.resolve(posExpansion)));
    }

    if (!tr.docChanged) return true;
    view.dispatch(tr);
    view.focus();

    return true;
  };

  // Force a rerender when the window is resized
  // This helps the autocomplete dropdown to be positioned correctly
  useWindowDimensions();

  const currentInputSpan = document.getElementsByClassName(
    AUTOCOMPLETE_DECORATION_CLASS,
  )[0];

  const editorPlacement = useScrollOffsets(editorContainerRef.current);
  const offset = currentInputSpan?.getBoundingClientRect();
  if (!offset) return null;

  const topInScrollContainer =
    offset.bottom - editorPlacement.top + editorPlacement.scrollTop;

  // We don't want the suggestion list to overflow the viewport, so we set a
  // max-height based on style.top. We also don't want the suggestion list to
  // be invisible, because that's weird, so we set a minimum max-height to 32,
  // which is the height of approx. 1 suggestion
  const remainingVerticalSpaceBelow = window.innerHeight - offset.bottom - 24; // make sure there is a little padding on the bottom
  const maxHeight = `${Math.max(remainingVerticalSpaceBelow, 32)}px`;

  if (!autocompleteState || !suggestions[activeElementIndex]) {
    return null;
  }
  return (
    <div
      className={
        "suggestion-item-list-container " +
        (areAutocompleteSuggestionsValid ? "" : "disabled")
      }
      style={{
        top: topInScrollContainer,
        left: offset.left,
        maxHeight,
        maxWidth: "100%",
      }}
    >
      {suggestions.length && (
        <div className="suggestion-item-list">
          {suggestions.map((s, i) => (
            <MenuItem
              key={s.id + i}
              setAsActiveElement={() => setActiveElementIndex(i)}
              isHighlighted={i === activeElementIndex}
              suggestion={s}
              pickIt={pickIt}
            />
          ))}
        </div>
      )}
    </div>
  );
};
